import { Headline, Stack } from "@secuis/ccp-react-components";

type MatrixHeaderProps = {
    title: string;
};

export const MatrixHeader = ({ title }: MatrixHeaderProps) => {
    return (
        <Stack>
            <Stack mb="S" mr="XS">
                <Headline bold={true}>{title}</Headline>
            </Stack>
        </Stack>
    );
};
