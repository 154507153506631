import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { FilterIcon } from "src/components/shared/filters";
import { CollapsedFilterWrapper } from "src/components/shared/filters/FilterToolbar.styles";
import { useInsightsPageContext } from "src/pages/Insights/InsightsPageProvider";
import { useFiltersCount } from "src/store/insights/FilterHooks";

import { InsightsFilterIconWrapperStyled } from "./InsightsFilterIcon.styles";

type InsightsFilterIconProps = {
    isAbsolutePositionEnabled?: boolean;
};

export const InsightsFilterIcon = ({ isAbsolutePositionEnabled = false }: InsightsFilterIconProps) => {
    const filtersCount = useFiltersCount();
    const { isFilterActive, setIsFilterActive } = useInsightsPageContext();
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    const filterIcon = <FilterIcon activeFiltersCount={filtersCount} onClick={() => setIsFilterActive(true)} isActive={isFilterActive} />;

    const wrappedIcon = isAbsolutePositionEnabled ? <InsightsFilterIconWrapperStyled>{filterIcon}</InsightsFilterIconWrapperStyled> : filterIcon;

    return !isMobile ? <CollapsedFilterWrapper>{wrappedIcon}</CollapsedFilterWrapper> : wrappedIcon;
};
