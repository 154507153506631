import { GlobalStyles, Toaster } from "@secuis/ccp-react-components";
import { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { AuthenticatedApp } from "./AuthenticatedApp";
import { ClientSwitcher } from "./ClientSwitcher";
import AuthGate from "./components/AuthGate";
import FeatureFlagProvider from "./components/FeatureFlags/FeatureFlagProvider";
import { InMemoryFeatureFlagProvider } from "./components/InMemoryFeatureFlagProvider";
import { IntercomProvider } from "./components/Intercom";
import { Loading } from "./components/shared/Loading";
import { i18nextInit } from "./i18next";
import { syncI18nCultures } from "./i18nextSync";
import { NotFound } from "./pages/Error/NotFound";
import { Insights } from "./pages/Insights";
import { InsightsContextWrapper } from "./pages/Insights/InsightsContextWrapper";
import { OverviewEvents } from "./pages/Insights/OverviewEvents/OverviewEvents";
import { InsightsSitePage } from "./pages/Insights/SitePage/InsightsSitePage";
import { Reports } from "./pages/Reports";
import { SharedReportPage } from "./pages/Reports/ReportsList/ReportDetails/SharedReportPage";
import { SharedTourSessionPage } from "./pages/Reports/ReportsList/ReportDetails/SharedTourSessionPage";
import { SingleReportPage } from "./pages/Reports/ReportsList/ReportDetails/SingleReportPage";
import { SchedulePage } from "./pages/Schedule/SchedulePage";
import { SitesPage } from "./pages/Sites/SitesPage";
import { store } from "./Store";
import { StyledComponentsManager } from "./styles/StyledComponentsManager";
import { UnauthenticatedApp } from "./UnauthenticatedApp";

i18nextInit();
syncI18nCultures();

const App = () => {
    return (
        <StyledComponentsManager>
            <Provider store={store}>
                <GlobalStyles />
                <BrowserRouter>
                    {/*Note: Suspense with a fallback is for i18n for when the translations are not yet loaded, it should wrap the components that use translations*/}
                    <Suspense fallback={<Loading size="L" />}>
                        <Toaster>
                            <Routes>
                                <Route
                                    path="/shared"
                                    element={
                                        <InMemoryFeatureFlagProvider>
                                            <UnauthenticatedApp />
                                        </InMemoryFeatureFlagProvider>
                                    }
                                >
                                    <Route path="/shared/tour-sessions/:sharedTourSessionId" element={<SharedTourSessionPage />} />
                                    <Route path="/shared/reports/:sharedReportId" element={<SharedReportPage />} />
                                    <Route path="*" element={<NotFound />} />
                                </Route>
                                <Route
                                    element={
                                        <IntercomProvider>
                                            <AuthGate>
                                                <FeatureFlagProvider>
                                                    <ClientSwitcher>
                                                        <AuthenticatedApp />
                                                    </ClientSwitcher>
                                                </FeatureFlagProvider>
                                            </AuthGate>
                                        </IntercomProvider>
                                    }
                                >
                                    <Route path="/" element={<Navigate to="/insights" />} />
                                    <Route path="/insights" element={<InsightsContextWrapper />}>
                                        <Route index element={<Insights />} />
                                        <Route path="overview-events/:categoryKey" element={<OverviewEvents />} />
                                    </Route>
                                    <Route path="insights/site/:siteId" element={<InsightsSitePage />} />
                                    <Route path="/reports" element={<Reports />} />
                                    <Route path="/sites" element={<SitesPage />} />
                                    <Route path="/locations/:locationId/reports/:reportId" element={<SingleReportPage />} />
                                    <Route path="/notfound" element={<NotFound />} />
                                    <Route path="/schedule/:locationId" element={<SchedulePage />} />
                                </Route>
                            </Routes>
                        </Toaster>
                    </Suspense>
                </BrowserRouter>
            </Provider>
        </StyledComponentsManager>
    );
};

export default App;
